@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS for APP.tsx*/
@layer components {
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

/* css code for common components*/
@layer components {
  body {
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-white-secondary;
  }

  .icon-styling {
    @apply flex flex-row items-center gap-1
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .link {
    @apply cursor-pointer underline text-primary hover:text-primary-dark;
  }

  .button {
    @apply border-primary hover:bg-primary hover:text-white font-bold;
  }

  .page-background {
    @apply bg-white-secondary;
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .html-link {
    color: blue;
    background-color: transparent;
    text-decoration: none;
  }

  .html-link:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

/* CSS for title*/
@layer components {
  .title-position-in-header {
    @apply mx-auto  py-6 px-4 sm:px-6 lg:px-8;
  }

  .h1-font-color {
    @apply text-6xl font-bold tracking-tight text-gray-dark;
  }

  .h3-font-color {
    @apply text-4xl font-bold tracking-tight text-gray-dark;
  }

  .h4-font-color {
    @apply text-3xl font-bold tracking-tight text-gray-dark;
  }

  .h5-font-color {
    @apply text-2xl tracking-tight text-gray-dark;
  }
}

/* CSS for navbar*/
@layer components {
  .navbar-bgc {
    @apply bg-gray;
  }

  .navbar-parent-div {
    @apply mx-auto px-2 sm:px-6 lg:px-8;
  }

  .navbar-containers {
    @apply relative flex z-40 h-16 items-center justify-between;
  }

  /*.nav-bar-mobile-div{
    @apply absolute inset-y-0 left-0 flex items-center sm:hidden;
  }

  .navbarMobileButton{
    @apply inline-flex items-center justify-center rounded-md p-2 text-gray-light hover:bg-gray hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white;
  }

  .navbarMobileOpenMM{
    @apply sr-only;
  }

  .navbarMobileXIcon{
    @apply block h-6 w-6;
  }

  .navbarMobileBarIcon{
    @apply block h-6 w-6;
  }*/

  .navbar-icon-names-div {
    @apply flex flex-1  cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start;
  }

  .navbar-icon-names-div-position {
    @apply flex flex-shrink-0 items-center;
  }

  .navbar-icon-hidden {
    @apply block h-8 w-auto lg:hidden;
  }

  .navbar-icon-current {
    @apply hidden h-8 w-auto lg:block;
  }

  .navbar-name-div {
    @apply hidden sm:ml-6 sm:block;
  }

  .navbar-name-position {
    @apply flex space-x-4;
  }

  .navbar-name-text-color {
    @apply bg-gray-dark text-white px-3 py-2 rounded-md text-sm font-medium;
  }

  .navbar-name-text-hover {
    @apply text-gray-light hover:bg-gray-dark hover:text-white px-3 py-2 rounded-md text-sm font-medium;
  }

  .nav-search-icon-div {
    @apply static inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 w-2/4;
  }

  .nav-search-position {
    @apply relative flex w-full px-4 flex-wrap items-stretch lg:ml-auto;
  }

  .search-bar-size {
    width: 100%;
  }

  
  .search-bar {
    transition: transform 0.3s ease-in-out;
  }

  .slide-in {
    transform: translateX(0);
  }

  .slide-out {
    transform: translateX(100%);
  }

  .nav-profile-div {
    @apply relative ml-3;
  }

  .nav-profile-btn {
    @apply flex rounded-full bg-gray text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray;
  }

  .nav-oum {
    @apply sr-only;
  }

  .nav-profile-icon {
    @apply h-8 w-8 rounded-full;
  }

  .nav-menu-div {
    @apply absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
  }

  .nav-menu-name {
    @apply block px-4 py-2 text-sm text-gray;
  }

  .navMenuName:active {
    @apply bg-gray-light;
  }
}

/* css for card*/
@layer components {
  .daily-over-image {
    @apply snap-center w-64 mx-4 flex flex-col justify-center items-center content-center text-center bg-white;
  }
  .hourly-over-image {
    @apply snap-center w-64 mx-4 flex flex-col justify-center items-center content-center text-center bg-white;
  }
  .card-layout {
    @apply snap-center w-64 rounded flex flex-col justify-center items-center text-center p-2 shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out;
  }
  
  .card-layout-full {
    @apply snap-center w-full rounded flex flex-col justify-center items-center text-center p-2 shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out;
  }

  .card-horizontal-layout {
    @apply snap-center w-full rounded flex flex-col justify-center items-center text-center shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out;
  }

  .card-layout-mobile {
    @apply snap-center w-full rounded flex flex-col justify-center items-center text-center p-0.5 shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out;
  }

  .card-layout {
    @apply last:snap-end;
  }

  .card-layout {
    @apply first:snap-start;
  }

  .card-title {
    @apply text-xl font-bold overflow-ellipsis text-gray-dark h-[4rem];
  }

  .card-date {
    @apply text-xl font-bold text-gray-dark;
  }

  .card-time {
    @apply text-2xl font-bold flex flex-col text-gray-dark;
  }

  .graph-title {
    @apply text-lg font-bold flex flex-col text-gray-dark;
  }

  .weather-icon-position {
    @apply flex grow items-center justify-center;
  }

  .weather-description {
    @apply text-gray mb-2;
  }

  .daily-weather-description {
    @apply text-gray mb-2 text-sm text-center;
  }

  .temperature {
    @apply text-3xl font-bold text-gray mx-1;
  }
}

/* css for compare component*/
@layer components {
  .compare-search-position {
    @apply flex w-full pt-2 md:px-10 justify-center;
  }
}

/* css for hourly forecast table component*/
@layer components {
  .table-padding {
    @apply md:px-4 px-2 py-4;
  }
  .table-padding-simple {
    @apply px-2 py-2;
  }
}

/* css for AncestorLinks component*/
@layer components {
  .ancestor-link {
    @apply cursor-pointer underline text-primary hover:text-primary-dark ml-1 mr-1;
  }
}

/* css for main card*/
@layer components {
  .parent-div {
    @apply w-full h-full rounded overflow-hidden shadow-lg bg-white;
  }

  .area-img {
    @apply object-cover w-full h-48;
  }

  .area-icon-link {
    @apply my-2 mx-2 cursor-pointer hover:scale-105 fill-primary hover:fill-primary-dark;
  }

  .area-icon-add-link {
    @apply mx-2 cursor-pointer hover:scale-105 fill-primary hover:fill-primary-dark;
    transform: translateY(3px);
  }

  .area-child-link {
    @apply my-2 mx-2 px-2 py-1 cursor-pointer hover:scale-105 bg-primary text-white hover:bg-primary-dark rounded-lg border;
  }

  .icon-btn {
    @apply cursor-pointer hover:scale-105 fill-primary hover:fill-primary-dark;
  }

  .child-div {
    @apply p-4;
  }

  .h4-title {
    @apply text-xl font-semibold tracking-tight;
  }

  .h6-title {
    @apply text-lg tracking-tight;
  }

  /* css for Stars Component*/
  @layer components {
    .stars-container {
      @apply relative inline-flex items-center pt-1;
    }
    .stars-star {
      @apply flex;
      color: grey;
      fill: #ffa500;
    }

    .stars-star:last-of-type {
      @apply mr-0;
    }

    .stars-overlay {
      @apply absolute top-0 right-0 bottom-0 bg-black opacity-70;
    }

    @supports (mix-blend-mode: color) {
      .stars-overlay {
        @apply mix-blend-color;
        opacity: unset;
      }
    }
  }
}
