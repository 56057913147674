@tailwind base;
@tailwind components;
@tailwind utilities;

.temperature-div {
    @apply flex justify-center w-full font-bold
}

.temperature-high-low {
    @apply flex items-center text-2xl font-bold text-gray-dark;
}

.temperature-high-low-mobile {
    @apply flex items-center text-lg font-semibold text-gray-dark;
}

.feels-like-heading {
    @apply text-gray-dark mb-2
}

.best-time-to-climb {
    @apply text-gray-dark text-lg pt-2
}

.best-time-to-climb-mobile {
    @apply text-gray-dark text-lg font-semibold pt-2
}

.feels-like-temperatures-div {
    @apply flex justify-center w-full font-bold
}

.feels-like-temperatures-high-low {
    @apply flex items-center text-xl font-bold text-gray-dark mb-1
}

.rain-wind-sun-rise-down-div {
    @apply flex justify-around w-full font-semibold
}

.icon-styling {
    @apply flex items-center text-black px-0 gap-0
}

.compare-remove-btn {
    @apply transition-transform transform hover:scale-105 absolute left-[4px] top-[4px] text-primary hover:text-primary-dark text-xl cursor-pointer;
}

.compare-actions-btn {
    @apply hover:scale-105 absolute text-primary hover:text-primary-dark text-xl cursor-pointer;
}


.favorites-remove-btn {
    @apply transition-transform transform hover:scale-105 absolute right-[4px] top-[4px] fill-red text-xl cursor-pointer;
}




