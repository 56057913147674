@tailwind base;
@tailwind components;
@tailwind utilities;

.feels-like-heading{
    @apply text-gray mb-2;
}

.feels-like-temperature{
    @apply text-xl font-bold text-gray-dark mb-2
}   

.rain-wind-hum-cloud-score-div{
    @apply flex justify-around w-full font-bold
}

.icons-styling-hourly{
    @apply flex items-center text-gray px-0;
}